import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=0a86fa14&scoped=true"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"
import style0 from "./_id.vue?vue&type=style&index=0&id=0a86fa14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a86fa14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tax: require('/app/components/Tax.vue').default,CCEmail: require('/app/components/CCEmail.vue').default,PaymentAll: require('/app/components/PaymentMethod/PaymentAll.vue').default})
